<template>
    <div class="projects" v-if="pageLoaded">
        <div class="settings__main">
            <div class="settings__heading">
                <div class="content">
                    <div class="content__section">
                        <div class="content__subtitle">
                            {{ $t('Settings.Projects.WhoCanSee') }}
                        </div>
                        <div class="content__text">
                            {{ $t('Settings.Projects.WhoCanSeeSub') }}
                        </div>
                        <ContextPicker 
                            :remoteValue="settings.project_viewers.roles" 
                            :loading = isLoading
                            :currentRolesPage="currentRolesPage"
                            @search="searchRoles($event)"
                            :page="currentRolesPage"
                            @paginate="paginateRoles($event)"
                            :options="rolesOptions" 
                            :label="$t('Settings.Projects.Roles')" 
                            @blur="setRoles($event, 'viewers')" 
                            :class="{'context-picker_off': settings.project_viewers.access_all}" 
                        />
                        <ContextPicker 
                            :loading = isLoading
                            :currentUsersPage="currentUsersPage"
                            @search="searchUsers($event)"
                            :page="currentUsersPage"
                            @paginate="paginateUsers($event)"
                            :remoteValue="settings.project_viewers.users" 
                            :options="getUsers" :label="$t('Settings.Projects.Users')" 
                            @blur="setUsers($event, 'viewers')"  
                            :class="{'context-picker_off': settings.project_viewers.access_all}" 
                        />
                        <div class="toggle-wrapper">
                            <span class="toggle__label">
                                {{ $t('Settings.Projects.Toggler') }}
                            </span>
                            <ToggleSwitch v-model="settings.project_viewers.access_all"/>
                        </div>
                    </div>
                    <Divider />
                    <div class="content__section">
                        <div class="content__subtitle">
                            {{ $t('Settings.Projects.WhoCanManage') }}
                        </div>
                        <div class="content__text">
                            {{ $t('Settings.Projects.WhoCanManageSub') }}
                        </div>
                        <ContextPicker
                            :loading = isLoading
                            :currentRolesPage="currentRolesPage"
                            @search="searchRoles($event)"
                            :page="currentRolesPage"
                            @paginate="paginateRoles($event)"
                            :remoteValue="this.settings.project_managers.roles" 
                            :options="rolesOptions" 
                            :label="$t('Settings.Projects.Roles')" 
                            @blur="setRoles($event, 'managers')" 
                            :class="{'context-picker_off': settings.project_managers.access_all}" 
                        />
                        <ContextPicker 
                            :loading = isLoading
                            @search="searchUsers($event)"
                            :currentUsersPage="currentUsersPage"
                            :page="currentUsersPage"
                            @paginate="paginateUsers($event)"
                            :remoteValue="this.settings.project_managers.users" 
                            :options="getUsers" 
                            :label="$t('Settings.Projects.Users')" 
                            @blur="setUsers($event, 'managers')"  
                            :class="{'context-picker_off': settings.project_managers.access_all}" 
                        />
                        <div class="toggle-wrapper">
                            <span class="toggle__label">
                                {{ $t('Settings.Projects.Toggler') }}
                            </span>
                            <ToggleSwitch v-model="settings.project_managers.access_all"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ContextPicker from '@/modules/settings/components/context-picker/ContextPicker.vue';
import RolesModule from '@/store/modules/roles/RolesModule';
import Divider from '@/modules/settings/components/divider/Divider.vue';
import TeamsModule from '@/store/modules/teams/TeamsModule';
import ApiClient from '@/api/api.client';
import Notify from '@/services/helpers/notify';
import ToggleSwitch from '@/components/ui/toggle-switch/ToggleSwitch.vue';
import SettingsModule from '@/store/modules/settings/SettingsModule';
export default {
    components: { ContextPicker, ToggleSwitch, Divider },
    data() {
        return {
            pageLoaded: '',
            isLoading: false,
            page: 2,
            firstSearchUsers: false,
            firstSearchRoles: false,
            currentUsersPage: 2,
            currentRolesPage: 2,
            users: '',
            settings: 
            {
                enabled: false,
                project_managers: 
                {
                    access_all: false,
                    roles: [],
                    users: [],
                },
                project_viewers: 
                {
                    access_all: false,
                    roles: [],
                    users: [],
                },
            },
        }
    },
    async mounted()
    {
        if(!this.getUsers.length)
        {
            await this.loadUsers();
        }
        await this.loadSettings().finally(() => 
        {
            this.pageLoaded = true;
        })
    },
    beforeDestroy()
    {
        // RolesModule.clearRoles();
        TeamsModule.clearTeams();
    },
    methods: 
    {
        setRoles(value,type) {
            if(type === 'viewers')
            {
                this.settings.project_viewers.roles = value;
            }
            else
            {
                this.settings.project_managers.roles = value;
            }
        },
        searchRoles(value)
        {
            this.firstSearchRoles = true;
            this.currentRolesPage = 1;
            // RolesModule.clearRoles();
            this.loadRoles(value);
        },
        searchUsers(value)
        {
            this.firstSearchUsers = true;
            this.currentUsersPage = 1;
            TeamsModule.clearTeams();
            this.loadUsers(value)
        },
        paginateUsers(value)
        {
            if(this.firstSearchUsers)
            {
                this.currentUsersPage++;
                value.page = this.currentUsersPage
            }
            this.loadUsers(value);
            this.firstSearchUsers = false
            this.currentUsersPage++;
        },
        paginateRoles(value)
        {
            if(this.firstSearchRoles)
            {
                this.currentRolesPage++;
                value.page = this.currentRolesPage
            }
            this.loadRoles(value)
            this.firstSearchRoles = false;
            this.currentRolesPage++;
        },
        setUsers(value,type) {
            if(type === 'viewers')
            {
                this.settings.project_viewers.users = value;
            }
            else
            {
                this.settings.project_managers.users = value;
            }
        },
        async loadRoles(payload)
        {
            this.isLoading = true;
            await RolesModule.fetchRoles(payload).finally(() => 
            {
                this.isLoading = false;
            })
        },
        async loadSettings() {
            if(!SettingsModule.getSettings.length)
                await SettingsModule.fetchGeneralSettings();

            this.settings = this.sectionSettings.filter((el) => el.slug === 'projects')[0].settings;
        },
        async loadUsers(payload) {
            this.isLoading = true;
            await TeamsModule.fetchTeams(payload).finally(() => this.isLoading = false);
        },
        updateChanges(value)
        {
            ApiClient.projects.setProjectSettings(value).then(() => Notify.success('Изменения сохранены'))
        },
    },
    computed: {
        sectionSettings()
        {
            return SettingsModule.getGeneralSettings
        },
        rolesOptions() 
        {
            return RolesModule.roles.map((el) => { return {id: el.id, title: el.name}});
        },
        getUsers()
        {
            return TeamsModule.teams.map(el => ({ id: el.id, title: `${el.first_name} ${el.last_name}`, img: el.avatar ? el.avatar : null, specialisations: el.specialisations }));
        },
    },
    watch: 
    {
        settings:
        {
            handler(newValue) 
            {
                if(this.pageLoaded)
                {
                    this.updateChanges(newValue);
                }
            },
            deep: true,
        },
    },
}
</script>
<style lang="scss">
.projects
{
    .context-picker_off
    {
        opacity: 0.4;
        pointer-events: none;
    }
    .toggle-wrapper
    {
        display: flex;
        align-items: center;
        .toggle__label
        {
            margin-right: 10px;
        }
    }
    .content
    {
        font-family: $font-family;
        font-style: normal;
        
        font-weight: 300;
        .content__subtitle
        {
            line-height: 22px;
            font-size: 18px;
            color: rgba(255, 255, 255, 0.8);
        }
        .content__text
        {
            margin-top: 8px;
            margin-bottom: 30px;
            font-size: 14px;
            line-height: 17px;
            color: rgba(255, 255, 255, 0.5);

        }
    }
}
</style>